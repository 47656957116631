import React from 'react';

import * as styles from './styles.module.scss';

export default class Offering extends React.Component {
  render() {
    const { name, description } = this.props.offering,
      classList = [styles.root];

    this.props.className && classList.push(this.props.className);

    return (
      <div className={classList.join(' ')}>
        {/* eslint-disable-next-line */}
        <a href={this.props.suppressCta ? null : "/contact/"} >
          <h5>
            {name.map((line, i) => <span key={i}>{line}</span>)}
          </h5>
          <div>
            {description.map((line, i) => <p key={i}>{line}</p>)}
          </div>
          {!this.props.suppressCta && (<div className={styles.cta}>Enroll ></div>)}
        </a>
        {this.props.children}
      </div>
    );
  }
}
