import React from 'react';
import Classes from 'components/Classes';
import Layout from "components/Layout";

export default class ClassesPage extends React.Component {
  render() {
    return <Layout
      location={this.props.location}
      title={"Stonybrook Strings | Classes Offered"}
      description={"Stonybrook Strings offers a variety of classes aimed at bringing the best musician out of your child, and providing you with the tools you need to support them."}>
      <Classes />
    </Layout>;
  }
}
