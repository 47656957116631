export const INDIVIDUAL = {
    name: ['Private Lessons'],
    description: [
        'Weekly private violin lessons for beginner, intermediate, and advanced players ages 3 and up.',
        'Lessons are scheduled in 30, 45, or 60 minute increments.',
        'All students participating in private lessons also participate in group lessons.'
    ]
};

export const GROUP = {
    name: ['Group String Classes'],
    description: [
        'Weekly group classes for all students (outside students are welcome).',
        'Intermediate-Beginner class Mondays 4:30 - 5:15 PM'
    ]
};

export const BEGINNER_THEORY = {
    name: ['Beginner Theory Classes'],
    description: [
        'Music theory for student in years 1 through 4 of their musical journey.',
        'Series of 8 to 10 sessions.'
    ]
};

export const COACHING = {
    name: ['Practice and Audition Coaching'],
    description: [
        'Sessions for intermediate and advanced students preparing for summer or school auditions.',
        'Learn new practice techniques and build healthy habits. Series of 4 to 6 sessions.'
    ]
};

export const PARENT_EVENTS = {
    name: ['Parent Events'],
    description: ['Workshops and meetups for parents of young instrumentalists.']
};