// extracted by mini-css-extract-plugin
export var root = "styles-module--root--1-T70";
export var soft = "styles-module--soft--xuz-3";
export var intro = "styles-module--intro--1A5jP";
export var forStudents = "styles-module--forStudents--3fYVR";
export var forParents = "styles-module--forParents--2x265";
export var calendar = "styles-module--calendar--2Z-22";
export var sbsClass = "styles-module--sbsClass--2r82x";
export var parentClass = "styles-module--parentClass--1zfdS";
export var date = "styles-module--date--2q3Kr";
export var link = "styles-module--link--yIDWl";
export var term = "styles-module--term--1MPXM";
export var contact = "styles-module--contact--3zIgh";