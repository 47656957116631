import React from 'react';

import Button from 'components/Button';
import Offering from 'components/Classes/Offering';
// import Term from 'components/Classes/Term';
// import SubscriptionForm from 'components/Subscribe';
import * as styles from './styles.module.scss';
import { getSortedParentEvents } from 'utils/events';
import moment from 'moment';
// import { FALL, SPRING, SUMMER } from 'data/terms';
import { INDIVIDUAL, GROUP, BEGINNER_THEORY, COACHING, PARENT_EVENTS } from 'data/offerings';

export default class Classes extends React.Component {
  render() {
    const parentEvents = getSortedParentEvents(),
      parentClassEls = [];

    parentEvents.forEach((evt, i) => {
      const evtDate = moment(evt.date, "YYYY-MM-DD HH:mm").format("MMM DD");

      parentClassEls.push(<span key={i} className={styles.parentClass}>
        <span className={styles.date}>{evtDate}:</span>
        <a className={styles.link} href={evt.url} target="_blank" rel="noopener noreferrer">{evt.name}</a>
      </span>)
    });

    return (
      <div className={styles.root}>
        <div>
          <div className={styles.intro}>
            <h1>Classes</h1>
            <p>
              Stonybrook Strings offers a variety of classes aimed at bringing the best musician out
              of your child, and providing you with the tools you need to support them in their
              musical journey.
            </p>
          </div>
          <div className={styles.forStudents}>
            <h2 className={styles.soft}>For the musicians</h2>
            <Offering className={styles.sbsClass} offering={INDIVIDUAL} />
            <Offering className={styles.sbsClass} offering={GROUP} />
            <Offering className={styles.sbsClass} offering={BEGINNER_THEORY} />
            <Offering className={styles.sbsClass} offering={COACHING} />
          </div>
          <div className={styles.forParents}>
            <h2 className={styles.soft}>For the parents</h2>
            <Offering className={styles.sbsClass} offering={PARENT_EVENTS} suppressCta={true}>
               {parentClassEls}
            </Offering>
          </div>
        </div>
        {/*<div className={styles.calendar}>*/}
        {/*  <h2 className={styles.soft}>Academic Calendar</h2>*/}
        {/*  <Term className={styles.term} term={SPRING} />*/}
        {/*  <Term className={styles.term} term={SUMMER} />*/}
        {/*  <Term className={styles.term} term={FALL} />*/}
        {/*</div>*/}
        <div className={styles.contact}>
          <h5>Get in touch with Maggie today</h5>
          <Button to="/contact/" text="Enroll" />
        </div>
        {/*<SubscriptionForm text="Get the scoop from Stonybrook Strings in your inbox:" />*/}
      </div>
    );
  }
}
